<template>
  <v-app id="app">
    <!-- Menu -->
    <Menu v-if="user" />
    <!-- AppBar -->
    <Header v-if="user" />
    <!-- Content -->
    <Loading v-if="validatingToken" />
    <Content v-else />
  </v-app>
</template>

<script>
import Header from "@/components/template/Header";
import Menu from "@/components/template/Menu";
import Content from "@/components/template/Content";
import Loading from "@/components/template/Loading";
import axios from "axios";
import { baseApiUrl, userKey } from "@/global";
import { mapState } from "vuex";
export default {
  name: "App",
  components: { Header, Menu, Content, Loading },
  computed: mapState(["user"]),
  data: () => ({
    validatingToken: true,
  }),
  methods: {
    async validateToken() {
      this.validatingToken = true;

      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.$store.commit("setUser", null);

      if (!userData) {
        this.validatingToken = false;
        this.$router.push({ name: "Auth" }).catch(() => {});
        return;
      }

      const res = await axios.post(`${baseApiUrl}/validateToken`, userData);

      if (res.data) {
        this.$store.commit("setUser", userData);
      } else {
        localStorage.removeItem(userKey);
        this.$router.push({ name: "Auth" }).catch(() => {});
      }

      this.validatingToken = false;
    },
  },
  created() {
    this.validateToken();
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
</style>