import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false,
    user: null,
    filterClient: {
      status: null,
      test: null,
      resale: null,
      search: "",
      modality: null
    },
  },
  mutations: {
    toggleMenu(state, isVisible) {
      if (isVisible === undefined) {
        state.drawer = !state.drawer
      } else {
        state.drawer = isVisible
      }
    },
    setUser(state, user) {
      state.user = user
      if (user) {
        axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
      } else {
        delete axios.defaults.headers.common['Authorization']
      }
    },
    setFilterClient(state, filter) {
      state.filterClient = filter
    }
  },
  actions: {
  },
  modules: {
  }
})
