<template>
  <v-container>
    <h2>Bem vindo, {{user.name}}.</h2>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  computed: mapState(["user"]),
};
</script>
