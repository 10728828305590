<template>
  <v-row class="d-flex align-center justify-center">
    <v-progress-circular
      :size="70"
      :width="7"
      color="black"
      indeterminate
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
</style>