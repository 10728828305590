<template>
  <v-app-bar dark app>
    <v-app-bar-nav-icon @click.stop="toggleMenu"></v-app-bar-nav-icon>
    <v-toolbar-title>RFS TECNOLOGIA</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-2" v-bind="attrs" v-on="on">
          {{user.name}}
          <v-icon right dark>mdi-arrow-down </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item link dense>
          <v-list-item-title @click="logout">Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { userKey } from "@/global";
export default {
  name: "Header",
  computed: mapState(["user"]),
  methods: {
    toggleMenu() {
      this.$store.commit("toggleMenu");
    },
    logout() {
      localStorage.removeItem(userKey);
      this.$store.commit("setUser", null);
      this.$router.push({ name: "Auth" });
    },
  },
};
</script>

<style>
</style>